import React, { useState } from "react"
import { useForm } from "react-hook-form"

import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import { subscribe } from "api"

const Signup = () => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { register, handleSubmit, errors, reset } = useForm({
    reValidateMode: "onSubmit",
  })
  const onSubmit = data => {
    setIsSubmitting(true)
    subscribe(data)
      .then(() => {
        toast.success("You're on my list!")
        reset()
        setIsSubmitting(false)
      })
      .catch(err => {
        toast.error("Something went wrong. Please try again later.")
        setIsSubmitting(false)
      })
  }

  var buttonClass =
    "inline-flex items-center bg-blue-200 p-2 rounded-md mt-2 border border-gray-300 hover:bg-blue-100 transition ease-in-out duration-150"
  if (isSubmitting) {
    buttonClass += " text-gray-500"
  }

  return (
    <div className="font-simple p-8 bg-gray-200 rounded-sm text-lg">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="text-xl font-bold">
          Want to know when a new story is published?
        </div>
        <div className="my-4">
          I don't write as often as I'd like, so it might feel like you never
          hear from me.
        </div>
        <div className="mb-4">
          But I promise I'll let you know when I have something worth sharing.
        </div>
        <div>
          <input
            id="email"
            name="email"
            disabled={isSubmitting}
            className="w-full p-2 mb-2"
            placeholder="Email"
            ref={register({
              required: "required",
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Please enter a valid email address",
              },
            })}
          />
          {errors.email && (
            <span className="text-red-800">
              {errors.email.message === "required"
                ? "This field is required"
                : errors.email.message}
            </span>
          )}
          <input
            id="likes"
            name="likes"
            disabled={isSubmitting}
            className="w-full p-2 mb-2"
            placeholder="Genres you like"
            ref={register}
          />
        </div>
        <button disabled={isSubmitting} type="submit" className={buttonClass}>
          {isSubmitting && (
            <svg
              class="animate-spin h-5 w-5 mr-2"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          )}
          Subscribe
        </button>
      </form>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnHover={false}
      />
    </div>
  )
}

export default Signup
