import axios from "axios"

const SIGNUP_URL =
  "https://script.google.com/macros/s/AKfycbxGm01FVLOavZO_KbDd2AAsMle-RBLfOVMMJnsw_x2HnIeLS6DiloBzqGr1f72DXjE-/exec"

export const subscribe = data => {
  return axios.post(
    SIGNUP_URL,
    { ...data, secret: "8a5240cc7a86432dad29ffcd801f7542" },
    {
      headers: {
        "Content-Type": "text/plain;charset=utf-8",
      },
    }
  )
}
