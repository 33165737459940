import React from "react"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

const Background = ({ bandClass }) => {
  const data = useStaticQuery(graphql`
    query {
      imageSharp(fixed: { originalName: { eq: "banner.jpg" } }) {
        fluid(quality: 99) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  `)

  return (
    <div className="static top-0 z-0">
      <div className="absolute top-0 bg-gradient-to-b from-gray-200 to-white w-full h-108 sm:h-80"></div>
      <div
        className={`absolute top-0 w-full h-48 mt-108 sm:mt-80 ${bandClass}`}
      >
        <Img fluid={data.imageSharp.fluid} className="w-full h-full" />
      </div>
    </div>
  )
}

export default Background
