import React from "react"
import { graphql, useStaticQuery } from "gatsby"

const TextSection = ({
  header,
  children,
  sectionClassName = "px-8 py-12 md:p-12",
  bodyClassName = "text-xl px-4 py-8 md:p-8",
  childClassName = "mb-4",
  showPen = false,
}) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { relativePath: { eq: "noun_Fountain Pen_16347.svg" } }) {
        edges {
          node {
            publicURL
          }
        }
      }
    }
  `)

  return (
    <div className={sectionClassName}>
      <h2>
        {header}
        {showPen && (
          <img
            className="inline ml-4 mb-4 float-right opacity-75"
            src={data.allFile.edges[0].node.publicURL}
          />
        )}
      </h2>
      <div className={bodyClassName}>
        {(children instanceof Array &&
          children.map((child, index) => (
            <div key={index} className={childClassName}>
              {child}
            </div>
          ))) || <div className={childClassName}>{children}</div>}
      </div>
    </div>
  )
}

export default TextSection
